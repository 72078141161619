import React from "react"
import { graphql } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"
import {
  useIntl,
  Link,
  FormattedMessage,
  changeLocale,
} from "gatsby-plugin-intl"

import { Container, Row, Col } from "react-bootstrap"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import PostCard from "../components/post-card"
import SEO from "../components/seo"

const PaddedBotDiv = styled("div")`
  padding-bottom: 30px;
`
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!, $tags: [String!]) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { tags: { in: $tags } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            slug
            title
            pathPrefix
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 640, maxHeight: 360, quality: 80) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }
  }
`
const Pagination = props => (
  <div className="pagination">
    <ul>
      {!props.isFirst && (
        <li>
          <Link to={props.prevPage} rel="prev">
            <span className="icon -left">
              <RiArrowLeftLine />
            </span>
          </Link>
        </li>
      )}
      {props.numPages > 1 &&
        Array.from({ length: props.numPages }, (_, i) => (
          <li key={`pagination-number${i + 1}`}>
            <Link
              to={`${props.blogSlug}${i === 0 ? "" : i + 1}`}
              className={props.currentPage === i + 1 ? "is-active num" : "num"}
            >
              {i + 1}
            </Link>
          </li>
        ))}
      {!props.isLast && (
        <li>
          <Link to={props.nextPage} rel="next">
            <span className="icon -right">
              <RiArrowRightLine />
            </span>
          </Link>
        </li>
      )}
    </ul>
  </div>
)

const BlogIndex = ({ data, pageContext }) => {
  const { currentPage, numPages, slug, title } = pageContext
  const blogSlug = `${slug}/`
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? blogSlug : blogSlug + (currentPage - 1).toString()
  const nextPage = blogSlug + (currentPage + 1).toString()

  const posts = data.allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => (
      <Col
        xl={4}
        lg={4}
        md={6}
        sm={6}
        xs={12}
        key={edge.node.id}
        className="px-5"
      >
        <PaddedBotDiv>
          <PostCard data={edge.node} />
        </PaddedBotDiv>
      </Col>
    ))
  let props = {
    isFirst,
    prevPage,
    numPages,
    blogSlug,
    currentPage,
    isLast,
    nextPage,
  }

  const intl = useIntl()
  const tt = key => {
    return intl.formatMessage({ id: key })
  }

  return (
    <Layout className="blog-page">
      <SEO
        title={title + " — Page " + currentPage + " of " + numPages}
        description={title}
      />
      <PaddedBotDiv className="pt-4">
        <h3 className="font-weight-bold text-center">{tt(title)}</h3>
      </PaddedBotDiv>
      <Container fluid="true">
        <Row>{posts}</Row>
      </Container>
      <Pagination {...props} />
    </Layout>
  )
}

export default BlogIndex
